<template>
  <div class="sctp-bg-white verified">
    <template v-if="phone">
      <el-alert
        :title="'已绑定手机：' + phone"
        :closable="false"
        type="warning" show-icon
        description="提示：请确保填写的姓名、身份证、手机号实名认证信息一致，否则实名认证失败！">
      </el-alert>
    </template>
    <template v-if="(account_type === 1 && whetherCertified === 1) || account_type === 3">
      <div class="type">
        <span>可升级为企业认证</span>
        <router-link to="/user/userInfo/businessverified">
          <span style="text-decoration: underline;" class="sctp-color-main">马上去认证 -></span>
        </router-link>
        <template v-if="whetherCertified === 1 && authHistory && authHistory.authresult === 2">
          <span class="mg-l15 fz-16 fc-red">
            企业升级失败：{{ authHistory.authmemo }}
          </span>
        </template>
      </div>
    </template>
    <div class="sctp-pad15" v-loading="!isInit">
      <el-form
        ref="form" label-width="120px" class="form"
        :disabled="!canEditForm"
        :model="form" :rules="formRules"
      >
        <el-form-item label="实名认证" class="auto">
          <div class="flex sctp-flex-aic">
            <div class="mg-r10" style="width: 110px;">
              <template v-if="whetherCertified === 1 || account_type === 3">已认证</template>
              <template v-else>未认证</template>
            </div>
            <template v-if="whetherCertified === 0 && account_type  !== 3">
              <el-tag size="small">待审核</el-tag>
            </template>
            <template v-if="whetherCertified === 1 || account_type === 3">
              <el-tag size="small" type="success">已完成</el-tag>
            </template>
            <template v-if="whetherCertified === 2">
              <el-tag size="small" type="danger">未通过</el-tag>
              <template v-if="authHistory">
                <span class="sctp-lh-normal mg-l10 fc-normal">原因：{{ authHistory.authmemo }}</span>
              </template>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input v-model="form.realName"></el-input>
          <div class="el-upload__tip">注：请填写账号负责人的真实姓名</div>
        </el-form-item>
        <el-form-item label="身份证号" prop="idCard">
          <el-input v-model="form.idCard"></el-input>
        </el-form-item>
        <template v-if="showMoreInfo">
          <el-form-item style="height: 0;padding: 0;margin-bottom: 0;"></el-form-item>
          <el-form-item class="auto" label="身份证正面" prop="positive">
            <el-upload
              :http-request="idCardBackUploadFunction"
              :data="{...uploadEnum.AUTHENTICATION}"
              action=""
              :show-file-list="false"
              :before-upload="beforeUpload">
              <el-image
                v-if="picture.back" :src="picture.back"
                style="width: 250px; height: 150px"
                fit="cover"></el-image>
              <el-image
                v-else
                style="width: 250px; height: 150px"
                src="/images/IDCard/1.png"
                fit="cover"></el-image>
              <div slot="tip" class="el-upload__tip">
                身份证正面（头像面）尺寸:宽≥243px，高≥153px；大小上限1M；格式JPG，GIF,PNG
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item class="auto" label="身份证反面" prop="back">
            <el-upload
              :http-request="idCardPositiveUploadFunction"
              :data="{...uploadEnum.AUTHENTICATION}"
              action="#"
              :show-file-list="false"
              :before-upload="beforeUpload">
              <el-image
                v-if="picture.positive" :src="picture.positive"
                style="width: 250px; height: 150px"
                fit="cover"></el-image>
              <el-image
                v-else
                src="/images/IDCard/0.png"
                style="width: 250px; height: 150px"
                fit="cover"></el-image>
              <div slot="tip" class="el-upload__tip">
                身份证反面（国徽面）尺寸:宽≥243px，高≥153px；大小上限1M；格式JPG，GIF,PNG
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="canEditForm">
            <el-button :loading="submitting" @click="onSubmitForm('form')" type="primary"
                       class="sctp-square">提交实名认证
            </el-button>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item>
            <el-button :loading="submitting" @click="onCheckFormClick('form')" type="primary"
                       class="sctp-square">提交实名认证
            </el-button>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>

import validator from '../../../../resources/js/async-validator'
import {user} from "../../../../apis";
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../../../resources/js/config')

export default {
  name: 'verified',
  data() {
    return {
      isInit: false,
      submitting: false,
      uploadEnum,
      idCardFlag:false,
      realnameAuthFlag:1,
      form: {
        realName: null,
        idCard: null,
        positive: null,
        back: null
      },
      picture: {
        positive: null,
        back: null
      },
      formRules: {
        realName: [
          {required: true, message: '真实姓名不能为空', trigger: ['blur', 'change']}
        ],
        idCard: [
          {required: true, message: '身份证不能为空', trigger: ['blur', 'change']},
          {validator: validator.validID, message: '身份证格式错误', trigger: ['blur', 'change']}
        ],
        positive: [
          {required: true, message: '请上传身份证正面照（国徽面）'},
        ],
        back: [
          {required: true, message: '请上传身份证背面照（头像面）'}
        ]
      },
      whetherCertified: 0,
      account_type: 0,
      phone: null,
      authHistory: null,
      showMoreInfo: false,
    }
  },
  methods: {
    beforeUpload(file) {
      const allowType = [
        'image/png',
        'image/jpeg'
      ];
      const isImage =
        allowType.some(type => file.type.startsWith(type))
      ;
      const isLtSize = file.size / 1024 / 1024 < 1
      if (!isImage) {
        this.$message.error('上传的文件只能是 图片 格式!')
      }
      if (!isLtSize) {
        this.$message.error('上传的文件大小不能超过 1M!')
      }
      return isImage && isLtSize && this.$message.info('正在上传') && true
    },
    idCardBackUploadFunction(item){
      let fm = new FormData()
      fm.append("file",item.file)
      fm.append("userId",this.user.userId)
      fm.append("path",this.uploadEnum.AUTHENTICATION.path)
      fm.append("type",'Back')
      this.$request.uploadIdCard(fm).then(res=>{
        if (res.retdata.uploadVO.code){
          const {src, preview} = res.retdata.uploadVO
          this.form.back = src;
          this.picture.back = preview;
          //判断正面是否上传成功
          if (res.retdata.idCardBackUploadFlag){
            this.form.realName = res.retdata.name
            this.form.idCard = res.retdata.idCard
          }
          //判断身份证正反两面都上传成功
          if (res.retdata.idCardFlag){
            this.idCardFlag = true
            this.realnameAuthFlag = 2
            this.onCheckFormClick('form')
          }
          this.$message.success('上传成功')
          this.$refs['form'].validateField('back');
        }else {
          this.$message.error('上传文件失败')
        }
      })
    },
    idCardPositiveUploadFunction(item){
      let fm = new FormData()
      fm.append("file",item.file)
      fm.append("userId",this.user.userId)
      fm.append("path",this.uploadEnum.AUTHENTICATION.path)
      fm.append("type",'Positive')
      this.$request.uploadIdCard(fm).then(res=>{
        if (res.retdata.uploadVO.code){
          const {src, preview} = res.retdata.uploadVO
          this.form.positive = src
          this.picture.positive = preview
          if (res.retdata.idCardFlag){
            this.idCardFlag = true
            this.realnameAuthFlag = 2
            this.onCheckFormClick('form')
          }
          this.$message.success('上传成功')
          this.$refs['form'].validateField('positive');
        }else {
          this.$message.error('上传文件失败')
        }
      })
    },
    getVerified() {
      return this.$request.post({
        reqcode: '1054',
        reqdesc: '实名认证状态',
        userId: this.user.userId,
        _t: new Date().getTime(), // 勿删
      }).then(res => {
        const {retdata} = res
        this.whetherCertified = retdata.auth
        this.account_type = retdata.account_type
        this.phone = retdata.phone
        this.authHistory = retdata.authHistory;
        this.form = {
          realName: retdata.realName || null,
          idCard: retdata.idCard || null,
          positive: retdata.positive || null,
          back: retdata.back || null
        }
        this.picture = {
          positive: retdata.positive,
          back: retdata.back
        }
        console.log(this.picture)
        if (retdata.positive || retdata.back) {
          this.showMoreInfo = true;
        }
      })
    },
    onCheckFormClick(formName) {
      this.$refs[formName].validate().then(res => {
        this.submitting = true;
        user.checkPersonalVerified({
          userId: this.user.userId,
          username: this.form.realName,
          phone: this.phone,
          idNumber: this.form.idCard,
          realnameAuthFlag:this.realnameAuthFlag
        }).then(res => {
          let retFlag = res.retdata;
          if (!retFlag) {
            // 认证失败
            if (!this.idCardFlag){
              this.$confirm('实名认证失败，是否进行人工认证?', '提示', {
                confirmButtonText: '人工认证',
                cancelButtonText: '取消认证',
                type: 'warning'
              }).then(() => {
                // 进行人工认证
                this.showMoreInfo = true;
              });
            }
          } else {
            // 认证成功，进行身份保存
            this.$request.post({
              reqcode: '1055',
              reqdesc: '提交实名认证',
              userId: this.user.userId,
              realName: this.form.realName,
              idCard: this.form.idCard,
              positive: this.form.positive,
              back: this.form.back,
              idcardAuthflag:1,
              type: 1
            }).then(res => {
              this.$message.success('认证成功')
              this.getVerified()
              this.goPage('/account/userbaseinfo')
            })
          }
        })
        .finally(() => {
          this.submitting = false;
        })
      });
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate().then(res => {
        this.submitting = true;
        this.$request.post({
          reqcode: '1055',
          reqdesc: '提交实名认证',
          userId: this.user.userId,
          realName: this.form.realName,
          idCard: this.form.idCard,
          positive: this.form.positive,
          back: this.form.back,
          idcardAuthflag:0,
          type: 1
        }).then(res => {
          this.$message.success('提交成功')
          this.getVerified()
        }).finally(() => {
          this.submitting = false;
        })
      }).catch(() => {
        this.$message.warning('请完成表单')
      })
    }
  },
  computed: {
    canEditForm() {
      let flag = true
      if (
        this.whetherCertified === 0 ||
        this.whetherCertified === 1 ||
        this.account_type === 3
      ) {
        flag = false
      }
      return flag
    }
  },
  beforeMount() {
    this.getVerified().finally(() => {
      this.isInit = true;
    })
  }
}
</script>

<style scoped lang="scss">
.verified {
  ::v-deep .el-form-item:not(.auto) {
    width: 500px;
  }
}

.type {
  padding: 10px 0 0 20px;
  font-size: 12px;
  color: #606266;
}
</style>
